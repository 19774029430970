import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import { colors, fonts, breakpoints } from '../style-utilities/variables';
import ButtonRed from './ButtonRed';

const Div = styled.div`
  margin: 0 auto;
  padding-top: 1.875em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  .copy,
  .image {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1 1 auto;
  }
  .copy p {
    font-size: 22px;
  }
  .content-button {
    text-align: center;
    margin-bottom: 2em;
  }
  .copy-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .block-container-0 .copy-image {
    @media (min-width: ${breakpoints.tablet}) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  }

  .block-container-1 {
    margin: 5em 0 2em;
    @media (min-width: ${breakpoints.tablet}) {
      margin: 8em 0 6em;
      .copy-image {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .image {
          order: 1;
        }
        .copy {
          order: 2;
          margin-left: 40px;
          margin-top: 60px;
        }
        #content-button {
          text-align: right;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .copy {
      width: 65%;
      margin-top: 70px;
    }
    .image {
      width: 35%;
      z-index: 5;
    }
    .content-button {
      text-align: left;
    }
  }
  /* IE11 Only CSS */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
`;
const HomeContentBlocks = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressPage(slug: { eq: "home" }) {
            acf {
              content_blocks {
                content_heading
                content_copy
                content_cta_text
                content_cta_url
                content_image {
                  source_url
                  alt_text
                }
              }
            }
          }
        }
      `}
      render={data => {
        let contentBlocks = data.wordpressPage.acf;

        // Preview logic
        if (props.isPreviewMode) {
          contentBlocks = props.content;
        }

        return (
          <Div className="page-container">
            {(() => {
              return contentBlocks.content_blocks.map((block, index) => {
                return (
                  <div className={'block-container-' + index}>
                    <div className={'copy-image'}>
                      <div className="copy">
                        <h2>{block.content_heading}</h2>
                        {ReactHTMLParser(block.content_copy)}
                        <div className="content-button">
                          <ButtonRed
                            textLabel={block.content_cta_text}
                            pageLink={block.content_cta_url}
                          />
                        </div>
                      </div>

                      <div className="image">
                        <p>
                          <img
                            src={
                              props.isPreviewMode
                                ? block.content_image.url
                                : block.content_image.source_url
                            }
                            alt={
                              block.content_image.alt_text || 'Brand to Local'
                            }
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                );
              });
            })()}
          </Div>
        );
      }}
    />
  );
};

export default HomeContentBlocks;
