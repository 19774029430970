import React, { Component } from 'react';
import styled from 'styled-components';
import ButtonRed from './ButtonRed';
import ReactHTMLParser from 'react-html-parser';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import AnsiraPeriod from './../static/ansira-white.svg';
import ModalVideo from 'react-modal-video';
import { render } from 'enzyme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 0.75em;
  // height: calc(100vh - 180px);
  // height: calc(var(--vh, 1vh) * 100 - 180px);
  max-width: 1500px;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 4em;

  h1,
  h2 {
    font-size: 48px;
    margin-bottom: 0;
  }
  h2 {
    color: white;
    margin-bottom: 0;

    display: inline-block;
  }
  h3 {
    color: ${colors.primary};
    margin-top: 94px;
    text-transform: none;
    font-family: 'daytona-bold';
    display: inline-block;
    padding-right: 10px;
  }
  .clickable {
    font-family: 'daytona-bold';
    font-size: 26px;
    border-bottom: 3px solid black;
    svg {
      margin-left: 5px;
      width: 10px;
      height: 26px;
    }
  }

  .animated-word {
    display: flex;
    position: absolute;
    overflow: hidden;
    z-index: 5;
    opacity: 0;
    height: 72px;
    background: ${colors.blue};
    padding: 10px 10px 0 10px;
    .ansira-period {
      position: relative;
      top: 26px;
      right: -3px;
      margin-bottom: 0;
      width: 20px;
    }
  }

  @keyframes animateWord {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .word-0 {
    animation: animateWord 1s step-start;
    -moz-animation: animateWord 1s step-start; /* Mozilla */
    -webkit-animation: animateWord 1s step-start; /* Safari 4.0 - 8.0 */
  }
  .word-1 {
    animation: animateWord 1s step-start 1s;
    -moz-animation: animateWord 1s step-start 1s;
    -webkit-animation: animateWord 1s step-start 1s;
  }
  .word-2 {
    animation: animateWord 1s step-start 2s;
    -moz-animation: animateWord 1s step-start 2s;
    -webkit-animation: animateWord 1s step-start 2s;
  }
  .word-3 {
    animation: animateWord 1s step-start 3s 1 forwards;
    -moz-animation: animateWord 1s step-start 3s 1 forwards;
    -webkit-animation: animateWord 1s step-start 3s 1 forwards;
  }

  .hero-sub {
    max-width:65%;
  }

  h2.hero-sub-title {
    color: ${colors.black} !important;
    font-family: ${fonts.header};
    font-size: 48px;
    line-height: 53px;
    margin-block-end: 0.83em;
  }

  .hero-sub p {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    max-width:65%;
  }

  @media (min-width: ${breakpoints.mobileSM}) {
    .hero-sub {
      max-width:100%;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .hero-sub {
      padding-top:2rem;
      max-width:100%;
    }
    .hero-sub p {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    max-width:65%;
  }
    padding-top: 0;
    h1 {
      margin-left: -7px;
    }
    h1,
    h2 {
      font-size: 108px;
    }
    h3 {
      margin-top: 134px;
    }
    .animated-word {
      padding: 3px 18px 0 10px;
      height: auto;
      .ansira-period {
        top: 76px;
        width: 24px;
        img {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.tabletMax}) and (orientation: landscape) {
    .hero-sub {
      max-width:65%;
    }
  }

  @media (min-height: ${breakpoints.tablet}) and (orientation: landscape) {
    .hero-sub {
      max-width:100%;
    }
  }

  @media (min-width: ${breakpoints.iPhone678}) {
    .center {
      margin-top: 2rem;
    }
    .hero-sub {
      max-width:100%;
      margin-top:2rem;
      margin-top:2rem;
    }

    .hero-sub p {
      max-width:100%;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .hero-sub p {
      max-width:65%;
    }
    h1,
    h2 {
      font-size: 158px;
    }
    h3 {
      margin-top: 192px;
    }
    .animated-word {
      padding: 2px 18px 0 10px;
      .ansira-period {
        top: 110px;
        width: 34px;
      }
    }
    .cta {
      margin-top: 30px;
      margin-left: 60px;
      border: none;
    }
  }

  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }

  .modal-video-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -35px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
    @media (min-width: 1000px) {
      right: -35px;
    }
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

class HomeHero extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBrowser: true,
      emailAddress: '',
      firstName: '',
      lastName: '',
      success: false,
      error: false,
      isOpen: false
    };

    // bind functions
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      let vh = window.innerHeight * 0.01;
      //set view height as a css variable to oiptimize mobile browser sizing
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }

  // open modal (set isOpen, false)
  openModal() {
    this.setState({
      isOpen: true
    });
  }

  render() {
    let firstLine = '';

    const props = this.props;

    const firstHeaderArray = props.content.hero_first_line.split(' ');
    firstHeaderArray.map((word, index) => {
      firstLine += `<span className=top-word-${index}> ${word}</span>`;
    });

    let imageURL = undefined;
    if (props.content.background_image != '') {
      imageURL = props.content.background_image.split('src="');
      imageURL = imageURL[1].split('"');
      imageURL = imageURL[0];
    }
    return (
      <Div
        className="page-container"
        style={{ backgroundImage: `url(${imageURL ? imageURL : ''})` }}
      >
        <div className="center">
          <h1>{ReactHTMLParser(props.content.hero_first_line)}</h1>
          {props.content.animated_copy && props.content.animated_copy.map((item, index) => {
            return (
              <div className={`animated-word word-${index}`}>
                <h2>{props.content.animated_copy[index].word} </h2>
                <div className="ansira-period">
                  <img src={AnsiraPeriod} alt="Ansira Logo" />
                </div>
              </div>
            );
          })}
          <h3>{ReactHTMLParser(props.content.hero_second_line)}</h3>
          {props.content.video_link ? (
            <>
              <ModalVideo
                channel="vimeo"
                autoplay
                isOpen={this.state.isOpen}
                videoId="588437019"
                onClose={() => this.setState({ isOpen: false })}
              />
              <a
                className={this.props.formatType + ' clickable'}
                onClick={this.openModal.bind(this)}
              >
                Watch Video
                <FontAwesomeIcon icon={faCaretRight} size="1x" />
              </a>
            </>
          ) : (
            <></>
          )}
          {/* Show sub header */}
          {/* Adding sub header and copy and button to hero for additional content when desired */}
          {props.content.hero_sub_header ? (
            <div className="hero-sub">
            <React.Fragment>
              <h2 className="hero-sub-title">{ReactHTMLParser(props.content.hero_sub_header)}</h2>
              {ReactHTMLParser(props.content.hero_sub_paragraph)}
              {props.content.hero_sub_button_text ? (
                <ButtonRed
                  textLabel={props.content.hero_sub_button_text}
                  pageLink={props.content.hero_sub_button_url}
                />
              ) : (
                ''
              )}
            </React.Fragment>
            </div>
          ) : (
            ''
          )}
          {props.content.hero_cta ? (
            <div className="cta">
              <ButtonRed
                textLabel={props.content.hero_cta}
                pageLink={props.content.hero_url}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </Div>
    );
  }
}

export default HomeHero;
