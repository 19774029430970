import React, { Component } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Arrow from './Arrow';
import ReactHTMLParser from 'react-html-parser';
import { colors, breakpoints } from '../style-utilities/variables';

const Div = styled.div`
  max-width: 1500px;
  margin: 30px auto 80px;

  .link-cta {
    color: ${colors.primary};
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    z-index: 5;
    justify-content: space-between;

    .link {
      display: block;
      line-height: 18px;
      margin-top: 3px;
      margin-right: 15px;
      z-index: 5;
      font-family: Verdana; 
      font-weight: 700;
    }
    :hover {
      text-decoration: underline;
    }
    .arrow-cta {
      display: block;
    }
  }

  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 25px;
    margin-bottom: 25px;
  }
  h4 {
    margin-bottom: 6px;
    z-index: 5;
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 50px;
    .page-container {
      max-width: 1300px;
    }
    .home-services {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .link-cta {
      justify-content: flex-start;
    }

    .service-container {
      width: 30%;
      padding: 40px 0;
    }
  }
`;

const Headline = (data) => {
  return !data || !data.headline || !data.headline.h3c_headline ? null :
  ( <h2>{data.headline.h3c_headline}</h2> );
}

const SubHeadlineCopy = (data) => {
  return !data || !data.text || !data.text.h3c_copy ? null :
  (<div className="copy">
    {ReactHTMLParser(data.text.h3c_copy)}
  </div>);
}

class HomeServicesLinks extends Component {
  render() {
    return (
      <Div>
        <div className="page-container">
          <Headline headline={this.props.content.h3c_headline_group} />
          <SubHeadlineCopy text={this.props.content.h3c_headline_group} />
          <div className="home-services">
            {(() => {
              return this.props.content.services_links.map((link, index) => {
                return (
                  <div className="service-container">
                    <h4>{link.headline}</h4>
                    <Link to={link.cta_url} className="link-cta">
                      <span className="link">{link.cta}</span>
                      <span className="arrow-cta">
                        <Arrow color={'#ED3424'} />
                      </span>
                    </Link>
                  </div>
                );
              });
            })()}
          </div>
          </div>
      </Div>
    );
  }
}

export default HomeServicesLinks;
