import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import ButtonGrey from '../ButtonGrey';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
import Arrow from '../Arrow';
import HomeGridItem from './HomeGridItem';
import EmailSignupForm from '../EmailSignupForm';
import _ from 'lodash';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7em auto 1em;
  max-width: 1500px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 150px auto 1em;
  }

  h2 {
    margin-bottom: 1em;
    font-size: 40px;
  }

  p.category {
    margin: 0;
    .tag {
      font-weight: bold;
      margin-bottom: 6px;
    }
  }

  .grid {
    /* css grid spec: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @media (min-width: ${breakpoints.tablet}) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    #item-1,
    #item-2,
    #item-3 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    #item-1 {
      order: 1;
    }
    #item-2 {
      order: 2;
    }
    #item-3 {
      order: 3;

      .img-container {
        width: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .tagged-content-copy {
        width: 100%;
      }
    }
    .news-widget {
      order: 4;
      border: 2px solid ${colors.lightGray};
      margin-bottom: 1.5em;
      padding: 1.5em 1em 1em;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .news-links {
        margin-bottom: 1.5em;
      }
      .news-link {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        border-bottom: 2px solid ${colors.lightGray};
        color: black;
        font-weight: bold;
        font-size: 16px;
        h5 {
          padding-right: 40px;
        }
      }
    }
    .email-sign-up {
      order: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 120px 1em;
      flex-direction: column;
      background: black;
      margin-bottom: 1.5em;
      h3 {
        color: white;
        text-transform: uppercase;
        font-family: 'daytona-condensed';
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      #item-1,
      #item-2,
      .news-widget,
      .email-sign-up {
        width: 49%;
      }
      #item-1 {
        order: 1;
      }
      #item-2,
      .email-sign-up {
        order: 2;
        margin-left: 2%;
        margin-right: 0%;
      }
      #item-3 {
        width: 100%;
        order: 3;
        .img-container {
          width: 48.5%;
        }
        .tagged-content-copy {
          width: 48%;
          margin-left: 2.5%;
        }
        .item-grid {
          flex-direction: row;
        }
      }
      .email-sign-up {
        order: 5;
      }
    }
    @media (min-width: ${breakpoints.desktop}) {
      #item-1,
      #item-2 {
        width: 32%;
      }
      #item-3 {
        width: 66%;
        order: 4;
      }
      .news-widget {
        order: 3;
      }
      .news-widget,
      .email-sign-up {
        width: 32%;
        margin-left: 2%;
      }
    }
  }
`;

/**
 * Content Grid component - pulls gridItems data from application state
 * and renders it within a grid.
 *
 * All data fetch actions are called in Layout.js but fetched in api.js
 *
 * uses gridItems redux modules
 */
class HomeGrid extends Component {
  renderGridItemsNotNews = () => {
    let caseStudies = [];
    // This is helper to filter out those dummy posts (Gatsby + Wordpress Issue)
    const gridItemsFiterDummmyPosts = _.filter(
      this.props.homeGridReducer.gridItems,
      function(o) {
        if (o.slug !== 'do-not-delete' && o.type !== 'news') {
          return o;
        }
      }
    );
    // End
    let caseStudyCount = 0;
    let caseCounterBottom = 0;
    return gridItemsFiterDummmyPosts.map((item, index) => {

      // get 3 items for grid
      if (item.type === 'case_study') {
        caseStudyCount++;
      }


      if (index <= 1) {
        return (
          <div
            key={item.slug}
            id={`item-${index + 1}`}
            filterkey={index + 1}
            className="items"
          >
            <HomeGridItem item={item} />
          </div>
        );
      }
      //last item on bottom row must be a case study
      if (caseStudyCount >= 1  && item.type === 'case_study') {
        caseCounterBottom++;
        if(caseCounterBottom === 1){
          return (
            <div key={item.slug} id="item-3" filterkey={3} className="items">
              <HomeGridItem item={item} displayWide="row" />
            </div>
          );
        }
      } else {
        return <></>;
      }
    });
  };

  renderNewsWidget = () => {
    // This is helper to filter out those dummy posts (Gatsby + Wordpress Issue)
    const gridItemsFiterDummmyPosts = _.filter(
      this.props.homeGridReducer.gridItems,
      function(o) {
        if (o.slug !== 'do-not-delete' && o.type === 'news') {
          return o;
        }
      }
    );
    // End

    return gridItemsFiterDummmyPosts.map((item, index) => {
      if (index <= 4) {
        return (
          <div
            key={item.slug}
            id={`news-${index + 1}`}
            filterkey={index + 1}
            className="news"
          >
            <Link to={`about/news/${item.slug}`} className="news-link">
              <h5>{item.title.rendered}</h5>
              <Arrow color="#ED3424" />
            </Link>
          </div>
        );
      }
    });
  };

  render() {
    const {
      gridItems,
      requestPending: gridRequestPending
    } = this.props.homeGridReducer;
    const {
      tagColors,
      requestPending: tagsRequestPending
    } = this.props.tagsReducer;

    return (
      <GridContainer className="page-container">
        <h2>New and Newsworthy</h2>
        {gridItems && !gridRequestPending && !tagsRequestPending && (
          <div className="grid">
            {this.renderGridItemsNotNews()}
            <div className="news-widget">
              <div className="news-links">
                <h3>Company News</h3>
                {this.renderNewsWidget()}
              </div>
              <div>
                <ButtonGrey textLabel="SEE ALL NEWS" pageLink="about/news" />
              </div>
            </div>
            <div className="email-sign-up">
              <h3>Get Intel From Ansira</h3>
              <EmailSignupForm formatType="red-button" />
            </div>
          </div>
        )}
        {!gridItems || (gridRequestPending && <div className="grid" />)}
      </GridContainer>
    );
  }
}

const mapStateToProps = ({ homeGridReducer, tagsReducer }) => ({
  homeGridReducer,
  tagsReducer
});

export default connect(mapStateToProps)(HomeGrid);
