import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Tag from '../Tag';
import ButtonGrey from '../ButtonGrey';

import { breakpoints, colors, fonts } from '../../style-utilities/variables';

const StyledGridItem = styled.div`
  margin: 0 auto;
  display: flex;
  border: 2px solid ${colors.lightGray};
  flex-direction: column;
  margin-bottom: 1.5em;
  &:hover {
    text-decoration: none;
  }
  a.readme {
    color: ${colors.primaryGray};
    text-decoration: underline;
  }
  .img-container {
    img {
      margin-bottom: 0;
    }
  }

  .tagged-content-copy {
    display: flex;
    flex-grow: 1;
    padding: 1em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .preview-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .readmore {
    text-decoration: underline;
  }

  /* layout for no image */
  .no-img {
    background: lightseagreen;
    color: ${colors.white};
    height: 100%;
    text-align: center;
    p {
      color: ${colors.white};
    }

    .title-link {
      color: ${colors.white};
      max-width: 75%;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
`;

function randID() {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36);
  var id = number.toString(36).substr(2, 9);
  return id;
}

const GridItem = props => {
  let item = props.item;
  let filterKey = props.filterKey;

  const postTypes = {
    post: {
      path: `/insights/blog/${item.slug}`
    },
    case_study: {
      path: `results/case-studies/${item.slug}`
    },
    news: {
      path: `about/news/${item.slug}`
    },
    white_paper: {
      path: `insights/white-papers/${item.slug}`
    }
  };

  if (item) {
    const tag = item.tag_names[0];
    var truncateLength;
    if ((filterKey = 2)) {
      truncateLength = 450;
    } else {
      truncateLength = 250;
    }
    const excerpt = _.truncate(item.acf.excerpt, {
      length: truncateLength,
      omission: '...'
    });

    var gridCardTag = 'category';
    if (typeof item.topic == 'undefined') {
      var tagIDs = ['31'];
    } else {
      var tagIDs = item.topic;
    }
    //const excerpt = item.acf.excerpt;
    var category = item.type;

    return (
      <StyledGridItem to={postTypes[item.type]['path']} className="item-grid">
        {item.better_featured_image && (
          <>
            <Link to={postTypes[item.type]['path']} className="img-container">
              <img
                src={item.better_featured_image.source_url}
                alt={item.better_featured_image.alt_text}
              />
            </Link>
            <div className="tagged-content-copy">
              {tag && <Tag tag={tag} />}

              <div className="card-content">
                {gridCardTag === 'category' ? (
                  <p className="category">
                    {tagIDs.slice(0, 1).map(({ item }, index) => {
                      return (
                        <Tag
                          tag={tagIDs[index]}
                          tagCategory={category}
                          color={colors.white}
                          borderColor={colors.primaryGray}
                          textColor={colors.primaryGray}
                          key={randID()}
                        />
                      );
                    })}
                  </p>
                ) : null}
              </div>

              <Link to={postTypes[item.type]['path']} className="title-link">
                <h4>{item.title.rendered}</h4>
              </Link>
              <div className="preview-text">{ReactHtmlParser(excerpt)}</div>
              <div>
                <ButtonGrey
                  textLabel="Read More"
                  pageLink={postTypes[item.type]['path']}
                />
              </div>
            </div>
          </>
        )}
      </StyledGridItem>
    );
  }
};

export default GridItem;
