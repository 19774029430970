import 'url-search-params-polyfill';
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';

//components
import HomeHero from '../components/HomeHero';
import HomeServicesLinks from '../components/HomeServicesLinks';
import HomeContentBlocks from '../components/HomeContentBlocks';
import HomeGrid from '../components/HomeGrid/HomeGrid';
import ContactBanner from '../components/ContactBanner';
import SEO from '../components/SEO';
import ButtonRed from '../components/ButtonRed';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import BigAnsira from './../static/ansira-man-background.svg';

const StyledDiv = styled.div`
  overflow-x: hidden;
  .down-link {
    display: none;
    svg {
      width: 40px;
      margin: 0 auto;
      transform: rotate(270deg);
      fill: ${colors.primary};
      stroke: ${colors.primary};
    }
  }
  .intro-section {
    max-width: 1500px;
    margin: 6em auto;
    h2 {
      z-index: 5;
    }
    p {
      font-size: 22px;
      z-index: 5;
    }
  }
  .big-ansira {
    display: none;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .down-link {
      display: block;
      display: flex;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .intro-section p {
      max-width: 65%;
    }
    .big-ansira {
      display: block;
      position: absolute;
      right: -290px;
      top: 600px;
      z-index: -1;
    }
  }
`;
class IndexPage extends Component {
  state = {
    viewPort: 'desktop',
    isBrowser: true,
    isPreviewMode: false,
    previewPayload: null
  };

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.setViewport);
    }
  }

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined',
      viewPort: window.innerWidth >= 768 ? 'desktop' : 'mobile'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.setViewport);
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }
  setViewport = () => {
    this.setState({
      viewPort:
        this.state.isBrowser && window.innerWidth >= 768 ? 'desktop' : 'mobile'
    });
  };
  render() {
    let data = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      data = this.state.previewPayload;
    }

    return (
      <StyledDiv className="animate">
        {!this.state.isPreviewMode && (
          <SEO
            isBlogPost={false}
            postData={data}
            postImage={DefaultSharingImage}
          />
        )}
        <HomeHero content={data.acf} />
        <img src={BigAnsira} alt="Ansira Logo" className="big-ansira" />
        <div className="intro-section page-container">
          <h2>{ReactHtmlParser(data.acf.header)}</h2>
          {ReactHtmlParser(data.acf.copy)}
          <ButtonRed
            textLabel={data.acf.center_button_text}
            pageLink={data.acf.center_button_url}
          />
        </div>
        <HomeServicesLinks content={data.acf} />
        <HomeContentBlocks
          isPreviewMode={this.state.isPreviewMode}
          content={
            this.state.isPreviewMode ? this.state.previewPayload.acf : null
          }
        />
        <HomeGrid />
        <ContactBanner
          headline={data.acf.contact_headline}
          cta={data.acf.contact_cta}
          url={data.acf.contact_url}
        />
      </StyledDiv>
    );
  }
}
export default IndexPage;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      type
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_first_line
        hero_second_line
        hero_cta
        hero_url
        hero_sub_header
        hero_sub_paragraph
        hero_sub_button_text
        hero_sub_button_url
        header
        copy
        video_link
        animated_copy {
          word
        }
        center_button_text
        center_button_url
        background_image
        contact_headline
        contact_cta
        contact_url
        h3c_headline_group {
          h3c_headline
          h3c_copy
        }
        services_links {
          headline
          cta
          cta_url
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;
